import React from "react"
import { Link } from "gatsby"
import "../pages/index.css"

const Footer = () => {
  return (
    <div class="footer" style={{display: `flex`, backgroundColor: `slateblue`, color: `white`}}>
       <p class="centreMe"style={{color: `white`}}><Link to="/">Home</Link> · <Link to="/england/">England</Link> · <Link to="/wales/">Wales</Link> · <Link to="/areas/">All Areas</Link></p>
    </div>
  )
}

export default Footer
