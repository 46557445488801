import React from "react"
import { Link } from "gatsby"
import "../pages/index.css"
import icon from "../images/house.svg"
import { Helmet } from "react-helmet"

const Header = () => {
  return (
    <div class="centreMe" style={{display: `flex`,marginTop: `1em`,marginBottom: `1em`}}>
          <Helmet><script async src="https://cse.google.com/cse.js?cx=012824496509365473763:ks49biqbreo"></script>
    </Helmet>

          <Link to="/"><img src={icon} alt="" style={{height: `30px`, width: `30px`}} /></Link>
          <h2 style={{marginTop: `8px`,marginLeft:`1em`}}>YourLocalArea</h2>
          <div style={{width: `100%`}}>
          <div style={{float: `right`, width: `300px`}}>
          <nav id="page-nav">
      <label for="hamburger">&#9776;</label>
      <input type="checkbox" id="hamburger"/>
      <ul>
        <li><a href="/">Home</a></li>
        <li><a href="/england/">England</a></li>
        <li><a href="/wales/">Wales</a></li>
        <li><a href="/areas/">All Areas</a></li>
        <div class="gcse-search"></div>
      </ul>
    </nav>
          </div>
          </div>
    </div>
  )
}

export default Header